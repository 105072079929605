<template>
  <div class="cases_box">
    <!--病例总页面-->
    <div class="cases_con posr">
      <div class="left_flex">
        <div @click="inquireProductList" class="left_create flex-x-y-c main_theme_color_333">
          <i class="el-icon-plus mr6"></i>
          {{ $t("cases.cases.xjbl") }}
        </div>
        <div class="cases_con_left">
          <ul class="left_ul" @click="selectedCases">
            <li class="left_single curp" v-for="(item, index) in casesLeft" :key="item.key" :class="{
              left_single_active: selectedKey === item.key || [0, 1].includes(index),
            }" :data-key="item.key">
              <div v-if="index === 0" class="main_theme_color_red pending_circle">
                ●
              </div>
              <div v-show="selectedKey === item.key" class="left_single_active"></div>
              {{ item.title }}
              {{
                `${item.key !== "all" ? `(${casesObj[item.key].count || 0})` : ""
                  }`
              }}
            </li>
          </ul>
        </div>
      </div>
      <div class="cases_con_right">
        <ul class="search_box">
          <li class="search_btn_div">
            <span class="search_btn flex-x-y-c curp fz14 main_theme_color_333"
              @click="changeRouteQuery({ pageNum: 1 })">{{ $t("common.message.cx") }}</span>
            <span class="reset_btn flex-x-y-c curp fz14 main_theme_color_333" @click="resetForm">{{
              $t("common.message.cz") }}</span>
          </li>
          <li :class="lang === 'zh_CN' ? 'mr30' : 'mr10'" class="search_single">
            <span>{{ $t("cases.cases.zs") }}: </span>
            <el-select style="width: 2rem" @change="(id) => {
                changeRouteQuery({ clinicId: id });
              }
              " v-model="casesForm.clinicId" clearable :placeholder="$t('common.message.qxz')">
              <el-option style="height: 0.4rem" v-for="item in clinicList" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </li>
          <li :class="lang === 'zh_CN' ? 'mr30' : 'mr10'" class="search_single">
            <span>{{ $t("cases.cases.blh") }}: </span>
            <el-input style="width: 2rem" :placeholder="$t('cases.cases.srblh')" v-model="searchCaseNumber" clearable>
            </el-input>
          </li>
          <li :class="lang === 'zh_CN' ? 'mr30' : 'mr10'" class="search_single">
            <span>{{ $t("cases.cases.hz") }}: </span>
            <el-input style="width: 2rem" :placeholder="$t('cases.cases.srhzm')" v-model="searchCasePatient" clearable>
            </el-input>
          </li>
        </ul>
        <template class="cases_list" v-if="casesList.length > 0">
          <div class="cases_single" @click.prevent="() => {
              toCasesDetail(item.caseList ? item.caseList[0] : {});
            }
            " v-for="item in casesList" :key="item.id">
            <img v-if="item.caseList && item.caseList.some(item => item.helpFlag === '1')" class="is-irtd-case"
              src="../../common/imgs/case/irdt.png" alt="" />
            <div class="cases-single-header">
              <div class="header-left">
                <div class="user-avatar" :style="{
                  backgroundImage: `url('${item.photo
                    ? $PicPrefix + item.photo
                    : defPhoto
                    }')`,
                }"></div>
                <div class="user-info">
                  <div class="user-name" :title="item.name">
                    {{ item.name }}
                  </div>
                  <div class="point"></div>
                  <div class="user-age">{{ `${item.age}岁/${item.sex && item.sex === '1' ? $t("cases.createUser.n") :
                    $t("cases.createUser.v")}` }}
                  </div>
                </div>
              </div>
              <div v-if="item.caseList" class="clinic-name" :title="item.caseList[0].clinicName">
                {{ item.caseList[0].clinicName }}
              </div>
            </div>
            <div class="cases-single-content" v-if="item.caseList">
              <div class="product-item" v-for="(cItem, cIndex) in item.caseList" :key="cIndex"
                @click.stop="() => toCasesDetail(cItem)">
                <div class="item-left">
                  <div class="product-name" :title="cItem.productName">
                    {{ cItem.productName }}
                  </div>
                  <div class="submit-date">{{ cItem.createDate }}</div>
                  <div class="case-number">{{ cItem.caseNumber }}</div>
                </div>
                <div class="item-right">
                  <div class="item-status">
                    {{ nextStatusObj[cItem.nextStatus] }}
                  </div>
                  <div class="item-action" v-if="casesNextObj[cItem.nextStatus] &&
                    cItem.nextProcessingFlag === '1'
                    " @click.stop="() => {
      toEditCases(cItem, item);
    }
    ">
                    <span v-if="casesNextObj[cItem.nextStatus] &&
                      cItem.nextProcessingFlag === '1'
                      ">
                      {{ casesNextObj[cItem.nextStatus].value }}
                    </span>
                    <span v-else-if="statusList.includes(cItem.nextStatus) &&
                      cItem.receiveNotifyFlag === '1'
                      ">
                      {{ $t("cases.cases.qsh") }}
                    </span>
                  </div>
                  <div v-else class="item-action-block"></div>
                  <img class="case-del-icon" v-if="cItem.nextStatus === 'to_submit' && cItem.reopenFlag !== '1'
                    " @click.stop="showConfirm(cItem.id)" src="../../common/imgs/case/case-del.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="no_data" v-else>
          <div class="no_data_pic"></div>
          <p class="no_data_p fz14 main_theme_color_666">
            {{ $t("home.home.zsmyblxx") }}
          </p>
        </div>
        <div :class="{ select_product_dialog_news: !isShowNews }" v-show="isShowProduct" class="select_product_dialog">
          <div class="product_type_name" @mouseover="productHoverFn(item.systemType, item.productList)"
            :class="{ product_item_active: productId === item.id }" v-for="(item, index) in productList" :key="index">
            <div class="product">{{ item.name }}</div>
            <p class="init_icon">
              <i class="el-icon-arrow-right main_theme_color"></i>
            </p>
            <div class="angle"></div>
          </div>
        </div>
        <div :class="{ select_product_dialog_news: !isShowNews }" v-show="productId"
          class="select_particular_product_dialog">
          <!-- <img v-if="productId === '1'" class="product-dialog-bg" src="/static/images/home/create-logo-bg.webp" alt=""> -->
          <el-collapse v-model="activeNames" v-if="productId === '1'" class="product-collapse">
            <el-collapse-item disabled v-if="productParticularList.filter(item => ['A', 'I'].includes(item.cureType)).length"
              title="成人" name="1">
              <template slot="title">
                <p>成人</p>
                <span>-</span>
              </template>
              <div v-for="(item, index) in productParticularList.filter(item => ['A', 'I'].includes(item.cureType))"
                @click="clickProductParticular(item)" @mouseover="productParticularId = item.id"
                @mouseleave="productParticularId = ''" class="product_item" :key="index">
                <p class="product_item_name" :title="item.name">{{ item.name }}</p>
                <el-popover width="300" :key="index + 'pop'" placement="bottom-end" trigger="hover">
                  <p class="mb10 fz12 main_theme_color_333">
                    <i class="el-icon-warning-outline main_theme_color"> </i>
                    {{ $t("common.common.jgwqy") }}
                  </p>
                  <p class="fz12 main_theme_color_333" v-for="(uClinic, ucx) in item.unSignedClinicList" :key="ucx">
                    {{ uClinic.name }}
                  </p>
                  <i slot="reference" v-show="item.unSignedClinicList && item.unSignedClinicList.length > 0
                    " class="product_item_init el-icon-warning-outline"></i>
                </el-popover>
              </div>
            </el-collapse-item>
            <el-collapse-item disabled
              v-if="productParticularList.filter(item => ['B', 'C'].includes(item.cureType)).length" title="儿童" name="2">
              <template slot="title">
                <p>儿童</p>
                <span>-</span>
              </template>
              <div v-for="(item, index) in productParticularList.filter(item => ['B', 'C'].includes(item.cureType))"
                @click="clickProductParticular(item)" @mouseover="productParticularId = item.id"
                @mouseleave="productParticularId = ''" class="product_item" :key="index">
                <p class="product_item_name" :title="item.name">{{ item.name }}</p>
                <el-popover width="300" :key="index + 'pop'" placement="bottom-end" trigger="hover">
                  <p class="mb10 fz12 main_theme_color_333">
                    <i class="el-icon-warning-outline main_theme_color"> </i>
                    {{ $t("common.common.jgwqy") }}
                  </p>
                  <p class="fz12 main_theme_color_333" v-for="(uClinic, ucx) in item.unSignedClinicList" :key="ucx">
                    {{ uClinic.name }}
                  </p>
                  <i slot="reference" v-show="item.unSignedClinicList && item.unSignedClinicList.length > 0
                    " class="product_item_init el-icon-warning-outline"></i>
                </el-popover>
              </div>
            </el-collapse-item>
            <el-collapse-item disabled
              v-if="productParticularList.filter(item => !['A', 'B', 'C', 'I'].includes(item.cureType)).length" title="其他"
              name="3">
              <template slot="title">
                <p>其他</p>
                <span>-</span>
              </template>
              <div v-for="(item, index) in productParticularList.filter(item => !['A', 'B', 'C', 'I'].includes(item.cureType))"
                @click="clickProductParticular(item)" @mouseover="productParticularId = item.id"
                @mouseleave="productParticularId = ''" class="product_item" :key="index">
                <p class="product_item_name" :title="item.name">{{ item.name }}</p>
                <el-popover width="300" :key="index + 'pop'" placement="bottom-end" trigger="hover">
                  <p class="mb10 fz12 main_theme_color_333">
                    <i class="el-icon-warning-outline main_theme_color"> </i>
                    {{ $t("common.common.jgwqy") }}
                  </p>
                  <p class="fz12 main_theme_color_333" v-for="(uClinic, ucx) in item.unSignedClinicList" :key="ucx">
                    {{ uClinic.name }}
                  </p>
                  <i slot="reference" v-show="item.unSignedClinicList && item.unSignedClinicList.length > 0
                    " class="product_item_init el-icon-warning-outline"></i>
                </el-popover>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div v-else v-for="(item, index) in productParticularList" @click="clickProductParticular(item)"
            @mouseover="productParticularId = item.id" @mouseleave="productParticularId = ''"
            :class="{ product_item_active: productParticularId === item.id }" class="product_item" :key="index">
            <p class="product_item_name curp">{{ item.name }}</p>
            <el-popover width="300" :key="index + 'pop'" placement="bottom-end" trigger="hover">
              <p class="mb10 fz14 main_theme_color_333">
                <i class="el-icon-warning-outline main_theme_color"> </i>
                {{ $t("common.common.jgwqy") }}
              </p>
              <p class="fz14 main_theme_color_333" v-for="(uClinic, ucx) in item.unSignedClinicList" :key="ucx">
                {{ uClinic.name }}
              </p>
              <i slot="reference" v-show="item.unSignedClinicList && item.unSignedClinicList.length > 0
                " class="product_item_init el-icon-warning-outline"></i>
            </el-popover>
          </div>
        </div>
        <el-pagination v-if="!isLoading" class="fr" :page-sizes="[10, 20, 30, 50, 100]" :current-page="casesForm.pageNum"
          :page-size="casesForm.pageSize" :style="casesList.length < 4 ? 'margin-top: 2rem;' : ''" @size-change="
            changeRouteQuery({
              pageSize: arguments[0],
              pageNum: 1,
            })
            " @current-change="
    changeRouteQuery({
      pageNum: arguments[0],
    })
    " background layout="total, sizes, prev, pager, next" :total="caseTotal">
        </el-pagination>
      </div>
    </div>
    <Confirm :confirmObj="confirmObj" @confirmHide="confirmHide" @confirmSubmit="confirmSubmit" v-show="isShowConfirm" />
    <div v-if="isShowBack" class="back_top" @click="backTop">
      <i class="el-icon-arrow-up fz16rem"></i>
    </div>
    <ExBirdie @reload="reload" ref="exBirdie" />
    <img @click="openDia" class="expressage_birdie" :src="casePaster" alt="" />
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
let once = function (ele, eventName, fn) {
  let callback = function (...args) {
    ele.removeEventListener(eventName, callback);
    fn(...args);
  };
  ele.addEventListener(eventName, fn, false);
  return () => ele.removeEventListener(eventName, callback);
};
import $ from "jquery";
import { getUserId, notifyMsg, removeCreate } from "common/js/util";
import {
  queryCasesList,
  casesStatistical,
  clinicList,
  removeCase,
  PublicUserCasePage,
} from "common/api/cases";
import { getDictList } from "common/api/public";
import { mapState } from "vuex";
import { defPhoto, BOY_ICON, GIRL_ICON } from "common/js/requireImage";
import FullLoading from "components/full-loading/full-loading";
import Confirm from "components/confirm-modal/confirm";
import { extend, pickBy, map, get } from "lodash";
import { getProductTypeListByDoctor } from "common/api/public";
import { toNumber } from "../../common/js/Fn";
import { mapMutations, mapActions } from "vuex";
import ExBirdie from "../../components/expressage_birdie/index";

const toObj = {
  to_submit: "/cases/create",
  to_resubmit: "/cases/create",
  to_deliver_model: "/cases/detail",
  to_deliver_model_remain: "/cases/detail",
  to_confirm_text: "/cases/view3d",
  to_pay_design: "/cases/paymentScheme",
  to_confirm_plan: "/cases/view3d",
  to_pay_production: "/cases/paymentScheme",
  to_deliver: "/cases/detail",
  to_done: "/cases/maintain",
  to_archive: "/cases/casesDone",
  archived: "/cases/restartCases",
  to_receive: "/cases/allLogistics",
  to_receive_remain: "/cases/allLogistics",
  to_receive_done: "/cases/allLogistics",
};
export default {
  data() {
    return {
      activeNames: ['1', '2', '3'],
      clickFn: "",
      isShowBack: false, //是否加载底部
      isLoading: false, //是否加载
      isShowConfirm: false,
      defPhoto,
      BOY_ICON,
      GIRL_ICON,
      casesLeft: [],
      casesObj: {},
      casesNextObj: {},
      initializeCasesObj: {},
      nextStatusObj: {},
      casesList: [],
      caseTotal: 0,
      clinicList: [],
      isScroll: true,
      domEle: null,
      statusList: ["to_receive_done", "to_receive", "to_receive_remain"],
      confirmObj: {
        tipText: this.$t("cases.cases.qrsc"),
        backText: this.$t("common.message.qx"),
        okText: this.$t("common.message.qd"),
      },
      removeId: "",
      lang: localStorage.getItem("user_lang") || "zh_CN",
      isShowProduct: false,
      productList: [],
      productParticularList: [],
      productId: "",
      productParticularId: "",
      diaBirdie: true,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    ...mapState({
      conditionForQuery: (state) => state.conditionForQuery,
      isShowNews: (state) => state.isShowNews,
    }),
    casePaster() {
      return this.language === "en_US"
        ? require("../../common/imgs/expressage_birdie/case_paster_en.png")
        : require("../../common/imgs/expressage_birdie/case_paster.png");
    },
    casesForm() {
      let query = this.$route.query;
      let params = {
        clinicId: query.clinicId || "",
        caseNumberForQuery: this.searchCaseNumber,
        patientNameForQuery: this.searchCasePatient,
        doctorId: getUserId(),
        pageNum: toNumber(query.pageNum, 1),
        pageSize: toNumber(query.pageSize, 10),
      }
      if (this.selectedKey === 'to_confirm_plan') {
        params['nextStatus'] = this.selectedKey
      } else {
        params['status'] = this.selectedKey
      }
      return params;
    },
    selectedKey: {
      get() {
        return this.$route.query.type || "all";
      },
      set(val) {
        this.casesForm.status = val === "all" ? "" : val;
      },
    },
    searchCaseNumber: {
      get() {
        return this.$route.query.caseNumber || "";
      },
      set(val) {
        let route = this.$route;
        this.$router.replace({
          path: route.path,
          query: extend({}, route.query, { caseNumber: val }),
        });
      },
    },
    searchCasePatient: {
      get() {
        return this.$route.query.casePatient || "";
      },
      set(val) {
        let route = this.$route;
        this.$router.replace({
          path: route.path,
          query: extend({}, route.query, { casePatient: val }),
        });
      },
    },
  },
  methods: {
    ...mapMutations(["HANDLE_MSG"]),
    ...mapActions({
      getMsgList: "actionGetMsgList", //病例详情
    }),
    get,
    reload() {
      this.queryCasesList();
    },
    openDia() {
      this.$refs["exBirdie"].openDia();
    },
    //产品类型hover 事件
    productHoverFn(id, list) {
      this.productId = id;
      this.productParticularList = list;
    },
    // 新增病例按钮点击
    inquireProductList() {
      getProductTypeListByDoctor().then((data) => {
        this.productList = data;
        return (this.isShowProduct = !this.isShowProduct);
      });
    },
    clickProductParticular(item) {
      let { id, cureType } = item;
      this.productParticularId = id;
      window.open(`/cases/create?productId=${id}`);
    },
    backTop() {
      $("html, body").animate({ scrollTop: 0 }, "fast");
    },
    handleScroll() {
      if (this.isScroll) {
        let height =
          document.documentElement.scrollTop || document.body.scrollTop;
        height > 700 ? (this.isShowBack = true) : (this.isShowBack = false);
      }
    },
    resetForm() {
      this.changeRouteQuery({
        type: "",
        caseNumber: "",
        casePatient: "",
        clinicId: "",
      });
    },
    toCasesDetail(obj) {
      const { id, name, curePlanId, nextStatus } = obj;
      let query = `?caseId=${id}&curePlanId=${curePlanId}`;
      nextStatus === "to_deliver_model" ||
        nextStatus === "to_deliver_model_remain"
        ? (query += "")
        : "";
      localStorage.setItem(
        "windowName",
        name + this.$t("common.common.blxq")
      );
      window.open("/cases/detail" + query, "_blank");
    },
    toEditCases(obj, item) {
      const {
        id,
        userInfo,
        cureNoteId,
        curePlanId,
        nextStatus,
        nextProcessingFlag,
        receiveNotifyFlag,
        phaseAdjustmentNumber,
        cureType,
        productId,
      } = obj;
      const url = toObj[nextStatus];
      let query = `?caseId=${id}&curePlanId=${curePlanId || ""}`;
      if (["to_pay_design", "to_pay_production"].includes(url)) {
        query += `&payType=${{ to_pay_design: 0, to_pay_production: 1 }[url]}`;
      }
      if (url === "/cases/maintain") {
        query += `&type=${nextStatus === "to_done" ? "done" : "archive"
          }&productId=${productId}`;
      }

      if (
        nextStatus === "to_deliver_model" ||
        nextStatus === "to_deliver_model_remain"
      ) {
        localStorage.setItem(
          "windowName",
          item.name + this.$t("common.common.blxq")
        );
      }
      if (nextStatus === "to_pay_production") {
        localStorage.setItem("payType", "1");
      }
      if (nextStatus === "to_archive") {
        localStorage.setItem("doneType", "archive");
      }
      if (receiveNotifyFlag === "1") {
        localStorage.setItem("casesDetail", JSON.stringify(obj));
        window.open("/cases/allLogistics" + query, "_blank");
      } else if (
        nextProcessingFlag === "1" ||
        this.statusList.includes(nextStatus)
      ) {
        if (
          (nextStatus === "to_confirm_text" ||
            nextStatus === "to_confirm_plan") &&
          cureType == "F"
        ) {
          let type = nextStatus === "to_confirm_text" ? "target" : "cure";
          query += `&schemeType=${type}&cureNoteId=${cureNoteId}`;
          window.open("/exocad?" + query);
          return;
        }
        if (
          nextStatus === "to_confirm_text" ||
          nextStatus === "to_confirm_plan"
        ) {
          let type = nextStatus === "to_confirm_text" ? "target" : "cure";
          query += `&schemeType=${type}&cureNoteId=${cureNoteId}`;
        }
        if (nextStatus === "to_deliver") query += "&delivery=1";
        if (url === '/cases/create' && phaseAdjustmentNumber !== 0) {
          return window.open(`/stage-adjustment?caseId=${id}&curePlanId=${curePlanId}`)
        }
        window.open(url + query);
      }
    },
    selectedCases(ev) {
      let type = ev.target.getAttribute("data-key") || "";
      this.changeRouteQuery({ type }, "1");
    },
    showConfirm(id) {
      this.removeId = id;
      this.isShowConfirm = true;
    },
    removeCases() {
      if (this.removeId) {
        this.isLoading = true;
        removeCase(this.removeId)
          .then(() => {
            this.isLoading = false;
            notifyMsg(this, "success", this.$t("cases.cases.gblysc"));
            this.queryCasesList("1");
            this.isShowConfirm = false;
            this.getMsgList({
              toUserId: getUserId(),
              status: "to_handle",
            });
            this.getLeftTotal("1");
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    confirmHide() {
      this.isShowConfirm = false;
    },
    confirmSubmit() {
      this.removeCases();
    },
    // 左侧列表数量
    casesStatistical() {
      // let allCount = 0;
      this.casesObj = {
        ...JSON.parse(JSON.stringify(this.initializeCasesObj)),
      };
      casesStatistical({
        clinicId: this.casesForm.clinicId || "",
        doctorId: getUserId() || "",
      }).then((data) => {
        data.forEach((item) => {
          this.casesObj[item.status]["count"] = item.count;
          // if(item.status !== 'to_handle'){
          //   allCount += +item.count;
          // }
        });
        // this.casesObj['all'].count = allCount;
      });
    },
    //病例列表
    queryCasesList(type = "0") {
      this.isLoading = true;
      if (type === "0") {
        this.casesStatistical();
      }
      const conditionForQuery =
        sessionStorage.getItem("conditionForQuery") || "";
      if (conditionForQuery) {
        this.casesForm.conditionForQuery = conditionForQuery;
      } else {
        delete this.casesForm.conditionForQuery;
      }
      this.casesForm.status === "all"
        ? (this.casesForm.status = "")
        : this.casesForm.status;
      if (this.casesForm.status === "curing") {
        this.casesForm["stageList"] = ['C','D'];
        this.casesForm.status = "";
      }
      PublicUserCasePage(pickBy(extend({}, this.casesForm)))
        .then((data) => {
          this.isLoading = false;
          this.casesList = data.list;
          this.caseTotal = data.total;
        })
        .catch(() => {
          this.isLoading = false;
        });
      sessionStorage.removeItem("conditionForQuery");
    },
    changeRouteQuery(args, num = "0") {
      let route = this.$route;
      this.$router.replace({
        name: route.name,
        query: extend({}, route.query, { pageNum: 1 }, args),
      });
      this.$nextTick(() => {
        this.queryCasesList(num);
      });
    },
    getLeftTotal(arg) {
      getDictList("case.status").then((data) => {
        const obj = {};
        const list = data.map((item) => {
          obj[item.key] = {
            name: item.value,
            count: "",
          };
          return {
            key: item.key,
            title: item.value,
          };
        });
        list.splice(2, 0, { key: "all", title: this.$t("common.message.qb") });
        this.casesLeft = list;
        console.log(list)
        this.casesObj = {
          ...JSON.parse(JSON.stringify(obj)),
          all: {
            name: this.$t("common.message.qb"),
            count: "",
          },
        };
        this.initializeCasesObj = {
          ...JSON.parse(JSON.stringify(obj)),
          all: {
            name: this.$t("common.message.qb"),
            count: "",
          },
        };
        this.queryCasesList();
      });
    },
  },
  components: {
    FullLoading, //加载中组件
    Confirm,
    ExBirdie,
  },
  watch: {
    conditionForQuery() {
      this.queryCasesList("1");
    },
    isShowProduct(val) {
      if (!val) {
        this.productId = "";
        this.productParticularId = "";
      }
    },
  },
  created() {
    this.HANDLE_MSG();
    document.title = this.$t("cases.cases.title");
    document.body.style.height = "auto";
    document.body.style.overflowY = "scroll";
    removeCreate();
    getDictList("case.next_status").then((data) => {
      const obj = {};
      data.forEach((item) => {
        obj[item.key] = item.value;
      });
      this.nextStatusObj = obj;
    });
    this.getLeftTotal();
    getDictList("case.next_status_button").then((data) => {
      const obj = {};
      data.forEach((item) => {
        obj[item.key] = item;
      });
      this.casesNextObj = obj;
    });
    clinicList().then((data) => {
      this.clinicList = data.map((item) => {
        const { clinicInfo = {} } = item;
        return {
          id: clinicInfo.id,
          name: clinicInfo.name,
        };
      });
    });
  },
  mounted() {
    sessionStorage.removeItem("casesDetail");
    window.addEventListener("scroll", this.handleScroll, false);
    this.domEle = $("body,html");

    let that = this;
    this.clickFn = once(document, "click", function (e) {
      if (
        ![
          "main_theme_color_btn main_big_btn left_create",
          "product_type_name",
          "product",
          "product_type_name product_type_name_active",
          "el-icon-arrow-right main_theme_color",
          "product_type_name product_item_active",
          'el-collapse-item__header focusing',
          'el-collapse-item is-active',
          'el-collapse-item__header is-active',
          "el-collapse product-collapse",
          "el-collapse-item__header",
          "el-collapse-item",
          'el-collapse-item__arrow el-icon-arrow-right is-active',
          'el-collapse-item__arrow el-icon-arrow-right'
        ].includes(e.target.getAttribute("class"))
      ) {
        that.isShowProduct = false;
      }
    });
  },
  beforeDestroy() {
    this.clickFn();
    sessionStorage.removeItem("conditionForQuery");
    window.removeEventListener("scroll", this.handleScroll, false);
    document.body.style.overflowY = "auto";
  },
};
</script>

<style scoped lang="scss">
.back_top {
  width: 0.4rem;
  height: 0.4rem;
  background-color: $main_theme_color;
  color: white;
  position: fixed;
  bottom: 1rem;
  right: 0.6rem;
  cursor: pointer;
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cases_con {
  width: 13.44rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: 50px;

  .left_flex {
    position: fixed;
  }

  .select_product_dialog {
    width: 2.78rem;
    max-height: 600px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 0 0.16rem 0 rgba(167, 167, 167, 0.35);
    position: fixed;
    top: 2.2rem;
    left: 2.6rem;
    z-index: 99;

    .product_type_name {
      width: 100%;
      height: 0.52rem;
      padding: 0.16rem 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: $main_theme_color_333;

      .product {
        width: 80%;
        line-height: 0.16rem;
        margin-right: 0.2rem;
      }

      .init_icon {
        font-size: 0.14rem;
      }

      .angle {
        width: 0.18rem;
        height: 0.1rem;
        background-image: url("../../../src/common/imgs/home/top_corner_mark.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -0.1rem;
        left: 0.42rem;
      }
    }

    .product_type_name_active {
      background-color: #fff3cf;
      color: $main_theme_color;
    }
  }

  .select_particular_product_dialog {
    max-width: 8rem;
    min-width: 3rem;
    max-height: 600px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 0 0.16rem 0 rgba(167, 167, 167, 0.35);
    position: fixed;
    top: 2.2rem;
    left: 5.5rem;
    z-index: 99;

    .product-collapse {
      max-height: 600px;
      overflow-y: auto;
      color: #333333 !important;

      /deep/ .el-collapse-item__header {
        flex-direction: row-reverse;
        justify-content: flex-end;
        border-bottom: none;
        padding-left: 0.16rem;
        color: #333333 !important;
        cursor: pointer !important;
        position: relative;
        background-color: transparent !important;

        span {
          font-size: 8px;
          margin-right: 8px;
        }

        .el-collapse-item__arrow {
          display: none;
        }

        &::before {
          content: '';
          width: 24px;
          height: 6px;
          border-radius: 3px;
          background-color: #fcc80e;
          position: absolute;
          bottom: 50%;
          left: 35px;
          z-index: 1;
          opacity: 0.8;
          transform: translateY(100%);
        }

        p {
          position: relative;
          z-index: 2;
        }

      }

      /deep/ .el-collapse-item__arrow {
        margin: 0 12px 0 0;
      }

      /deep/ .el-collapse-item {
        
        &:last-child {

          .el-collapse-item__content {

            .product_item {
              &:last-child {
                p {
                  border-bottom: none;
                }
              }
            }

          }

        }

      }

      /deep/ .el-collapse-item__content {
        padding: 0px;

        .product_item {
          padding: 0px 0.16rem;
          border-bottom: none;
          
          p {
            padding: 0.16rem 0;
            height: 100%;
          }

          &:last-child {
            p {
              border-bottom: 1px solid #e5e5e5;
            }
          }

        }

      }
      
      /deep/ .el-collapse-item__wrap {
        border-bottom: none;
      }

    }

    .product_item {
      width: 100%;
      padding: 0.16rem 0.2rem;
      line-height: 0.18rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      color: $main_theme_color_333;

      &:hover {
        color: $main_theme_color;
        background: #f9f9f9;
      }

      .product_item_name {
        width: 100%;
        margin-right: 0.2rem;
      }

      .product_item_init {
        cursor: pointer;
      }

      .angle {
        width: 0.07rem;
        height: 0.07rem;
        background-image: url("../../../src/common/imgs/home/black_corner_mark.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 0;
      }

      .circle_black_corner_mark {
        background-image: url("../../../src/common/imgs/home/circle_black_corner_mark.png") !important;
      }

      .active_yellow_corner_mark {
        background-image: url("../../../src/common/imgs/home/yellow_corner_mark.png") !important;
      }

      .circle_active_yellow_corner_mark {
        background-image: url("../../../src/common/imgs/home/circle_active_yellow_corner_mark.png") !important;
      }
    }

    .product_item_active {
      color: $main_theme_color;
      background-color: #f2f2f2;
    }
  }

  .left_create {
    width: 240px;
    height: 52px;
    font-size: 0.16rem;
    color: $main_theme_color_333;
    background: #fcc80e;
    cursor: pointer;
    border-radius: 6px;
  }

  .cases_con_left {
    background-color: #fff;
    border-radius: 6px;
    width: 2.4rem;
    height: 389px;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .left_ul {
      width: 100%;
      text-align: center;

      li:nth-child(2) {
        border-bottom: 1px solid #e5e5e5;
      }

      .left_single {
        color: $main_theme_color_333;
        font-size: 0.16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 54px;
        border-right: 0.04rem solid transparent;
        transition: all 0.3s;
        position: relative;
        cursor: pointer;

        .pending_circle {
          position: absolute;
          right: 0.67rem;
          top: 0.1rem;
        }

        &:hover {
          background: #f9f9f9;
        }

        .left_single_active {
          width: 4px;
          height: 32px;
          background: #fcc80e;
          position: absolute;
          left: 0;
        }
      }

      .left_single_active {
        color: $main_theme_color;
      }
    }
  }

  .cases_con_right {
    width: 10.74rem;
    overflow: hidden;
    position: absolute;
    left: 270px;
    padding-bottom: 20px;

    .search_box {
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      margin-bottom: 0.22rem;
      color: $main_theme_color_333;
      font-size: 0.18rem;
      position: relative;

      .search_single {
        display: flex;
        align-items: center;

        span {
          font-size: 16px;
          color: $main_theme_color_333;
          margin-right: 4px;
        }
      }

      .search_btn_div {
        display: flex;
        align-items: center;
        font-size: 0.16rem;
        margin-left: 0.4rem;

        .search_btn {
          width: 96px;
          height: 36px;
          background: #fcc80e;
          border-radius: 4px;
          color: $main_theme_color_333;
        }

        .reset_btn {
          width: 96px;
          height: 36px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #bbbbbb;
          margin-left: 10px;
          color: $main_theme_color_333;
        }
      }
    }

    .cases_single {
      cursor: pointer;
      width: 99%;
      margin: 0 auto 20px;
      min-height: 148px;
      height: auto;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.12);
      position: relative;
      display: flex;
      flex-direction: column;

      .is-irtd-case {
        position: absolute;
        width: 48px;
        right: 0;
        top: 0;
      }

      .cases-single-header {
        width: calc(100% - 48px);
        margin: 0 auto;
        height: 56px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #e5e5e5;
        font-size: 18px;
        color: #333;

        .header-left {
          width: 345px;
          display: flex;
          align-items: center;

          .user-avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            margin-right: 20px;
          }

        }

        .user-info {
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #333;

          .user-name {
            max-width: 210px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .point {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin: 0 10px;
            background: #333;
          }
        }

        .clinic-name {
          max-width: 300px;
          font-size: 14px;
          color: #666;
          margin-left: 32px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .cases-single-content {
        flex: 1;
        padding: 16px 0 16px;
        word-break: break-all;

        .product-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          height: 72px;
          transition: 0.2s linear;
          font-size: 16px;
          color: #333;
          line-height: 22px;
          padding: 0 52px;

          .item-left {
            display: flex;
            align-items: center;

            .product-name {
              width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 48px;
            }

            .case-number {
              margin-left: 78px;
            }
          }

          .item-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;

            .case-del-icon {
              position: absolute;
              right: -28px;
              top: 50%;
              width: 12px;
              height: 12px;
              transform: translateY(-50%);
            }

            .item-status {
              width: 120px;
              color: #fcc80e;
              margin-right: 64px;
            }

            .item-action {
              cursor: pointer;
              width: 124px;
              height: 36px;
              background-color: #fcc80e;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .item-action-block {
              min-width: 124px;
            }
          }

          &:hover {
            background-color: #f9f9f9;
          }
        }
      }
    }

    .no_data {
      margin: 0.92rem auto 1.6rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .no_data_pic {
        width: 2.04rem;
        height: 1.54rem;
        background-size: 100% 100%;
        background-image: url("../../common/imgs/case/case_list_empty.png");
      }

      .no_data_p {
        color: #666666;
        font-size: 0.16rem;
        margin-top: 0.15rem;
      }
    }
  }
}

.expressage_birdie {
  display: block;
  width: 214px;
  height: 104px;
  position: fixed;
  top: 564px;
  right: 12px;
  cursor: pointer;
}

.product_item_active {
  color: $main_theme_color;
  background-color: #f2f2f2;
}

.select_product_dialog_news {
  top: 1.9rem !important;
}</style>
